import React, { useContext, useEffect } from 'react';
import AppContext from '../context/index';
import CardComponent from './CardComponent';
// import UsernameForm from "./UsernameForm";
// import Uploader from "./Uploader";
// import SiteHeading from "./SiteHeading";
import { Container, Row, CardGroup, Col } from "react-bootstrap";

const Homepage = (props) => {

    const { example, setExample } = useContext(AppContext);

    const numberOfCards = example.ownedCards.length;
    console.log("NUMBER OF OWNED CARDS IS " + numberOfCards)

    //create an empty array to store the random numbers
    let randomNumbers = [];


    function randomIntFromInterval(min, max) { // generate random number and push it to the randomNumbers array
        randomNumbers.push(Math.floor(Math.random() * (max - min + 1) + min));
    }

    //loop through 6 times and call the randomIntFromInterval function
    for (let i = 0; i < 6; i++) {
        randomIntFromInterval(0, numberOfCards)
    }

    console.log("RANDOM NUMBERS " + JSON.stringify(randomNumbers))

    // // pull the objects from teh entire array using the positions determined by the ramdom numbers
    // //TODO: move this to useeffect????
    let resultArr = randomNumbers.map(i => example.ownedCards[i])

    console.log("resultArr " + JSON.stringify(resultArr))
    // setExample((prevcontext => {
    //     return {
    //         ...prevcontext,
    //         randomCards: resultArr
    //     }
    // }))

    // useEffect(() => {
    //     console.log("IN USAE EFFECT")
    //     console.log("NUMBER OF OWNED CARDS in usefffect IS " + numberOfCards)

    //     //loop through 6 times and call the randomIntFromInterval function
    //     // for (let i = 0; i < 6; i++) {
    //     //     randomIntFromInterval(0, numberOfCards)
    //     // }
    // }, [randomNumbers]

    // )

    // useEffect(() => {

    //     //loop through 6 times and call the randomIntFromInterval function
    //     for (let i = 0; i < 6; i++) {
    //         randomIntFromInterval(0, numberOfCards)
    //     }

    //     console.log("RANDOM NUMBERS " + JSON.stringify(randomNumbers))

    //     // pull the objects from teh entire array using the positions determined by the ramdom numbers
    //     //TODO: move this to useeffect????
    //     resultArr = randomNumbers.map(i => example.ownedCards[i]);
    // }, [randomIntFromInterval, randomNumbers]);

    return (
        <>
            <Row>

                <Col><h1>Washington Commanders Cards</h1></Col>
            </Row>
            <Row>
                <Col>

                    <p>
                        On this site I am tracking my personal collection of Washington Redskins / Washington Football Team / Washington Commanders cards.
                    </p>
                    <p>You can browse my collection of cards and also take a look at some of the cards I am still hunting.</p>
                </Col>
                {/* <Col>
                    <h2>Share your collection</h2>
                    Sync your card collection to the cloud and automatically create a
                    web page showing off the cards you own and the cards you want.
                </Col>
             */}
            </Row>
            <hr />
            <Row>
                <h2>
                    Some random cards from my Washington Commanders collection.
                </h2>
                {/* <CardGroup props={props}>{example.randomCards.map((card) => <CardComponent {...props} card={card} />)}</CardGroup> */}

                <CardGroup props={props}>{resultArr.map((card) => <CardComponent {...props} card={card} />)}</CardGroup>

            </Row>

            <hr />
            {/* <Row>
                <Col xs="6">
                    <h2>New user?</h2>
                    <p>
                        If you're not already a user please take a look at the video below
                        to see how you can easily import your card information
                    </p>
                    <p>
                        Please{" "}
                        <a
                            href="https://docs.google.com/spreadsheets/d/1p5fohCD8pX4Q-JXvtLWvdMI_9S80N4UjZVWiVaObp7I/copy"
                            target="_blank" rel="noopener noreferrer"
                        >
                            make a copy of the Google doc to get started
                        </a>
                    </p>{" "}
                </Col>
            </Row> */}
            <hr />
            {/* </Container> */}
        </>
    );
};

export default Homepage;
