import React, { useContext, useEffect } from 'react';
import './App.css';
import { refreshEbayTokenSetup, getUsersCards } from './helpers/helpers'
import { useAuth0 } from '@auth0/auth0-react';
import { Container } from "react-bootstrap";
import Homepage from './components/Homepage';
import Navigation from './components/Navigation';
// import User from './components/User';
// import Terms from './components/Terms'
// import Privacy from './components/Privacy';
import CollectionContainer from './components/CollectionContainer';
import {
  Routes,
  Route,
} from "react-router-dom";
import AppContext from './context/index';
// import MapComponent from './components/MapComponent';


function App() {
  // CI test
  // const { isAuthenticated, user } = useAuth0();
  const user = 'jinky32';
  const { example, setExample } = useContext(AppContext);
  // console.log("user " + JSON.stringify(user))



  const getCards = async (username) => {
    try {
      const usersCards = await getUsersCards(username);
      // console.log("OWNED CARDS" + JSON.stringify(usersCards.ownedCards))

      setExample((prevcontext => {
        return {
          ...prevcontext,
          ownedCards: usersCards.ownedCards,
          wantedCards: usersCards.wantedCards
        }
      }))
    } catch (err) {
      throw err;
    }
  };

  let ownedCollectionTypes = []
  let wantedCOllectionTypes = []

  // console.log(example.wantedCards)

  //if (isAuthenticated) {
  // console.log("OWNED CARDS FROM CONTEXT" + JSON.stringify(example.ownedCards))
  // console.log("WANTED CARDS FROM CONTEXT" + JSON.stringify(example.wantedCards))
  // ownedCollectionTypes = [...new Set(example.ownedCards.map(item => item.collection_type))]
  // wantedCOllectionTypes = [...new Set(example.wantedCards.map(item => item.collection_type))]
  //TODO: these sets need to also have manufacturer and series added to them and then the values added to the link
  //maybe something like the below which return the whole object, then pick values out for the URLs
  // let seen = Object.create(null),
  //   result = example.ownedCards.filter((o) => {
  //     var key = ["brand", "manufacturer"].map((k) => o[k]).join("|");
  //     if (!seen[key]) {
  //       seen[key] = true;
  //       return true;
  //     }
  //   });

  // let ownedCollectionTypes = []
  example.ownedCards.forEach((thisRow) => {
    if (ownedCollectionTypes.find((otherRow) => (thisRow['brand'] === otherRow['brand']) && (thisRow['manufacturer'] === otherRow['manufacturer']) && (thisRow['year'] === otherRow['year'])) === undefined) {
      ownedCollectionTypes.push(thisRow);
    }
  })

  example.wantedCards.forEach((thisRow) => {
    if (wantedCOllectionTypes.find((otherRow) => (thisRow['brand'] === otherRow['brand']) && (thisRow['manufacturer'] === otherRow['manufacturer']) && (thisRow['year'] === otherRow['year'])) === undefined) {
      wantedCOllectionTypes.push(thisRow);
    }
  })

  // console.log(ownedCollectionTypes);

  // console.log('test is ' + JSON.stringify(result));
  // ownedCollectionTypes = Object.values(result);

  // ownedCollectionTypes = [...new Set(example.ownedCards.map(item => item.year))]
  // wantedCOllectionTypes = [...new Set(example.wantedCards.map(item => item.year))]
  // console.log("owned collection types " + JSON.stringify(ownedCollectionTypes))
  // console.log("wanted collection types " + JSON.stringify(wantedCOllectionTypes))
  // }

  useEffect(() => {
    // if (isAuthenticated) {
    //   getCards(user)
    // }
    getCards(user)
  }, [user]);

  useEffect(() => {
    refreshEbayTokenSetup();
  }, [refreshEbayTokenSetup]);


  return (
    <Container>
      <div className="App">
        <Navigation ownedCollectionNav={ownedCollectionTypes} wantedCollectionNav={wantedCOllectionTypes} />
        <Routes>
          <Route path="/" element={<Homepage />} />
          {/* <Route path="map" element={<MapComponent />} /> */}
          {/* <Route path=":userName" element={<User />} /> */}
          {/* <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms-and-conditions" element={<Terms />} /> */}
          {/* ****Owned Card Routes**** */}
          {/*  TODO: **SPORT DYNAMIC** - most of these routes need to be updated to include sport / collection */}
          {/* <Route path=":userName/own" element={<CollectionContainer status="Own" />} /> */}
          <Route path=":userName/:collection_type/own" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/availability/:forTrade" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/brand/:brand" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/brand/:brand/variation/:variation" element={<CollectionContainer status="Own" />} />
          {/* <Route path=":userName/own/:card_id" element={<CollectionContainer status="Own" />} /> */}
          <Route path=":userName/:collection_type/own/:card_id" element={<CollectionContainer status="Own" />} />

          {/* <Route path=":userName/own/:card_id" element={<CollectionContainer status="Own" />} /> */}
          <Route path=":userName/:collection_type/own/career-stage/:career_stage" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/card_title/:card_title" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/forTrade/:forTrade" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/team/:team" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/type/:id" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/series/:series" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/variation/:variation" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/year/:year" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/year/:year/brand/:brand" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/year/:year/series/:series" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/year/:year/brand/:brand/variation/:variation" element={<CollectionContainer status="Own" />} />
          <Route path=":userName/:collection_type/own/year/:year/variation/:variation" element={<CollectionContainer status="Own" />} />

          {/* ****Wanted Card Routes**** */}
          {/* <Route path="marthamaybrowny/want" element={<CardTable status="Want" />} /> */}
          <Route path=":userName/:collection_type/want" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/availability/:forTrade" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/brand/:brand" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/brand/:brand/variation/:variation" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/:card_id" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/career-stage/:career_stage" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/card_title/:card_title" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/forTrade/:forTrade" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/team/:team" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/type/:id" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/series/:series" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/variation/:variation" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/year/:year" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/year/:year/brand/:brand" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/year/:year/series/:series" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/year/:year/brand/:brand/variation/:variation" element={<CollectionContainer status="Want" />} />
          <Route path=":userName/:collection_type/want/year/:year/variation/:variation" element={<CollectionContainer status="Want" />} />

        </Routes>
      </div>
    </Container>



  );
}

export default App;
