import React, { useMemo, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useTable, useFilters, usePagination } from 'react-table';

import AppContext from '../context/index';
import { EbayColumns } from '../helpers/columns'
// import { refreshEbayTokenSetup } from '../helpers/helpers'

// import BTable from 'react-bootstrap/Table';
import { Table, Row, Button } from "react-bootstrap";

export const EbayTable = (props) => {
    const [ebay, setEbay] = useState({
        data: [],
        loading: false,
        error: false,
        results: false,
    });

    const ebayData = async (player, brand, series) => {


        //auth token is retrieved from ebay and stored in localstorage.  It is refereshed via refreshEbayTokenSetup in helpers.js
        const oauthToken = localStorage.getItem('authTokenEbay');

        //set up the ebay query
        let url =
            "https://api.ebay.com/buy/browse/v1/item_summary/search?q=" +
            player +
            "&" +
            brand +
            "&" +
            series

        //make the request
        return await axios
            .get(url, {
                'headers': {
                    'Authorization': `Bearer ${oauthToken}`,
                    'X-EBAY-C-ENDUSERCTX': `affiliateCampaignId=5338762217,affiliateReferenceId=webapp`, //this is use my affiliate code
                },
            }).then((result) => {
                // //console.log("££££££EBAY SERCH RESULT£££££" + JSON.stringify(result));
                //check is there are any results.
                if (
                    result.data.total === "0"
                ) {
                    //if not set results state to false
                    setEbay({
                        results: false,
                    })
                } else {
                    // if there are results put relevant part of response object into state
                    setEbay({
                        data:
                            result.data.itemSummaries,
                        loading: false,
                        error: false,
                        results: true,
                    })
                }
            })
    };

    useEffect(() => {
        // refreshEbayTokenSetup();
        ebayData(props.card[0].card_title, props.card[0].brand, props.card[0].series);
    }, [props.card]);


    // const params = useLocation();
    const { example, setExample } = useContext(AppContext);

    const columns = useMemo(() => EbayColumns, [])

    const data = useMemo(() => ebay.data, [ebay.data])


    const {
        getTableProps,
        getTableBodyProps,
        selectedFlatRows,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useFilters,
        usePagination
    )
    const { pageIndex, pageSize } = state

    return (

        <>{ebay.results ? <>
            <h2>Ebay Auctions for {props.card[0].card_title}, {props.card[0]?.brand}  {props.card[0]?.series} </h2>
            <Row>
                <Table striped bordered hover responsive {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>

                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>


                </Table>
                <div>
                    <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </Button>{' '}
                    <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </Button>{' '}
                    <Button variant="success" onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </Button>{' '}
                    <Button variant="success" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </Button>{' '}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type='number'
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            style={{ width: '50px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => setPageSize(Number(e.target.value))}>
                        {[50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </Row>
        </>
            : <h1>Data not here</h1>}</>
    );
};
