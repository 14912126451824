import React, { useContext } from 'react';
import { useLocation, useParams, Link } from "react-router-dom";
import { Card, Col, ListGroup, ListGroupItem, Toast } from "react-bootstrap";
import AppContext from '../context/index';
// import FileUpload from './FileUpload';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'react-bootstrap/Button'
import ImageModal from './ImageModal'




// function CardComponent(card, index, props) {
function CardComponent(props, card) {
    const { isLoading, isAuthenticated, user } = useAuth0();
    let location = useLocation();

    //split the path into an array.  The length of this array is used to determine which component to render
    let pathValues = location.pathname.split('/')

    console.log("location.pathname IN CARDCOMP" + JSON.stringify(location.pathname))
    console.log("location.pathname IN CARDCOMP length is " + pathValues.length)

    let params = useParams();
    console.log("PARAMS IN CARDCOMP " + JSON.stringify(params))
    // //console.log("USER IN CARDCOMP " + JSON.stringify(user.nickname))

    const { example, setExample } = useContext(AppContext);

    let imagePath = 'https://ik.imagekit.io/lvlmsd70nws/';

    const addDefaultSrc = (ev) => {
        ev.target.src =
            "https://ik.imagekit.io/lvlmsd70nws/fallback.jpg";
    }

    // const ImageModalValues = {
    //     clickedCardID: props.card.card_id,
    //     player: props.card.card_title,
    //     brand: props.card.brand,
    //     series: props.card.series,
    //     variation: props.card.variation,
    //     imagePath: imagePath
    // }

    return (

        <Col className="container-fluid mt-4 align-items-stretch d-flex justify-content-center">
            {/* <p1>hello from card comp {props.card.brand}</p1> */}
            <Card
                style={{ width: "18rem" }}
                key={props.card?.card_id}
                className="box h-100"
            >
                <Card.Header>
                    {/* if this is an individual card then set the heading to H1, if not set to H2 */}
                    {params.card_id ? (
                        <h1>
                            {props.card?.card_title} <br />
                            {props.card?.brand} - {props.card?.series} - {props.card?.variation}
                        </h1>
                    ) : (
                        // TODO: setting an H2 without an H2 is semantically incorrect
                        <Link to={`/jinky32/Football/own/${props.card?.card_id}`}> <h2>
                            {props.card?.card_title} <br />
                            {props.card?.brand} - {props.card?.series} - {props.card?.variation}
                        </h2>
                        </Link>
                    )}
                </Card.Header>
                <Card.Img
                    onError={addDefaultSrc}
                    variant="top"
                    src={`${imagePath}${props.card?.userName}-${props.card?.card_id}.jpg`}
                    // src={`${card.image_path}${card.card_id}.jpg`}
                    fluid="true"
                />
                <Card.Body>
                    {" "}
                    {params.card_id && <Button
                        onClick={() => {
                            setExample((prevcontext => {
                                return {
                                    ...prevcontext,
                                    modalShow: true,
                                    imageModalValues: {
                                        // set the values to show within the modal display
                                        clickedCardID: props.card?.card_id,
                                        player: props.card?.card_title,
                                        brand: props.card?.brand,
                                        series: props.card?.series,
                                        variation: props.card?.variation,
                                        imagePath: imagePath,
                                        userName: params?.userName
                                    }
                                }
                            }))
                        }
                        }
                    >
                        View Larger Image
                    </Button>}
                    {example.modalShow && <ImageModal
                        // show={this.state.modalShow}
                        show={example.modalShow}
                        onHide={() => setExample((prevcontext => {
                            return {
                                ...prevcontext,
                                modalShow: false,
                            }
                        }))}
                    />}


                    {/* {example.fileUploaded === false && user.nickname === params.userName && params.card_id ? ( */}
                    {/* {user?.nickname === params.userName && params.card_id ? (
                        <FileUpload />
                    ) : (
                        <div></div>
                    )} */}
                    {/* <FileUpload /> */}
                    {/* {this.context.imageHandling.fileUploaded === false && this.context.authenticatedUser.username === this.context.filterArgs.userName && this.context.pathValues.argument === "card_id" ? (
                  <FileUpload />
                ) : (
                  <div></div>
                )} */}
                </Card.Body>

                <ListGroup className="list-group-flush">
                    {example.imageHandling?.fileUploaded === true ? (
                        <ListGroupItem>
                            {/* Show current ebay auctions for the card that is showing. */}
                            <Toast className="bg-success">
                                <Toast.Header>
                                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                                    <strong className="me-auto">TradingCardClub</strong>
                                    {/* <small>11 mins ago</small> */}
                                </Toast.Header>
                                <Toast.Body>Image uploaded. Please refresh the page to see it.</Toast.Body>
                            </Toast>
                        </ListGroupItem>
                    ) : (
                        <div></div>
                    )}

                    <ListGroupItem>
                        <b>Card Number - </b>
                        {props.card?.card_number}
                    </ListGroupItem>
                    <ListGroupItem>
                        <b>Print Run - </b>
                        {props.card?.print_run}
                    </ListGroupItem>
                    <ListGroupItem>
                        <b>Career Stage -</b> {props.card?.career_stage} -{" "}
                    </ListGroupItem>
                    <ListGroupItem>
                        <b>For Trade -</b> {props.card?.forTrade}
                    </ListGroupItem>
                </ListGroup>
                <Card.Footer>{props.card?.team}</Card.Footer>
            </Card>
        </Col>
    );
}

export default CardComponent;
