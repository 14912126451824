import React from "react";
import { Link } from "react-router-dom";




import {
    Navbar,
    Nav,
    NavItem,
    NavDropdown,
    Row
} from "react-bootstrap";

function Navigation(props) {

    let navItems = props.ownedCollectionNav.map((item, index) =>
        [item.year, item.manufacturer, item.brand])
    console.log(navItems)
    let sortedNavItems = navItems.sort();
    console.log(sortedNavItems)
    console.log("HERE ARE PROPS IN NAV " + JSON.stringify(props.ownedCollectionNav))
    // const { user, isAuthenticated, isLoading } = useAuth0();
    return (
        <>
            <Row>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand as={Link} to="/">
                        Washington Commanders <br /> Cards
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto w-100 nav-justified">
                            <NavItem eventkey={2} href={`/jinky32/Football/own`}>
                                <Nav.Link as={Link} to={`/jinky32/Football/own`}>
                                    My Full Collection
                                </Nav.Link>
                            </NavItem>
                            <NavDropdown title={`Owned Cards by Set`} id="basic-nav-dropdown">
                                {/* {props.ownedCollectionNav.map((item, index) =>
                                    <NavDropdown.Item as={Link} to={`/jinky32/${item}/own`} key={index}> {item} Cards</NavDropdown.Item>
                                )} */}
                                {/* {props.ownedCollectionNav.map((item, index) =>
                                    // userName/:collection_type/own/year/:year/brand/:brand"
                                    <NavDropdown.Item as={Link} to={`/jinky32/Football/Own/year/${item.year}/brand/${item.brand}`} key={index}> {item.year} {item.manufacturer} {item.brand}  Cards</NavDropdown.Item>
                                )} */}
                                {sortedNavItems.map((item, index) =>
                                    // userName/:collection_type/own/year/:year/brand/:brand"
                                    <NavDropdown.Item as={Link} to={`/jinky32/Football/Own/year/${item[0]}/brand/${item[2]}`} key={index}> {item[0]} {item[1]} {item[2]}  Cards</NavDropdown.Item>
                                )}

                            </NavDropdown>
                            <NavItem eventkey={2} href={`/jinky32/Football/want`}>
                                <Nav.Link as={Link} to={`/jinky32/Football/want`}>
                                    All Wanted Cards
                                </Nav.Link>
                            </NavItem>
                            <NavItem eventkey={2} href={`/jinky32/Football/own/forTrade/Yes`}>
                                <Nav.Link as={Link} to={`/jinky32/Football/own/forTrade/Yes`}>
                                    Cards Available for Trade
                                </Nav.Link>
                            </NavItem>
                            {/* {props.ownedCollectionNav.length === 1 ? <NavItem
                                eventkey={1}
                                href={`/jinky32/${props.ownedCollectionNav}/own`}
                            >
                                <Nav.Link
                                    as={Link}
                                    to={`/jinky32/${props.ownedCollectionNav}/own`}
                                >
                                    jinky32's Owned {props.ownedCollectionNav} Cards
                                </Nav.Link>
                            </NavItem> :  */}

                            {/* } */}


                            {/* <NavItem eventkey={1} href={`/privacy`}>
                                <Nav.Link as={Link} to={`/privacy`}>
                                    Privacy
                                </Nav.Link>
                            </NavItem>
                            <NavItem eventkey={2} href={`/terms-and-conditions`}>
                                <Nav.Link as={Link} to={`/terms-and-conditions`}>
                                    Terms of Use
                                </Nav.Link>
                            </NavItem> */}

                            {/* <NavItem eventkey={3} >
                                <AuthenticationButton />
                            </NavItem> */}
                            {/* <NavItem eventkey={4} >
                                        <TwitterMentionButtonComponent />
                                    </NavItem>
                                    <NavItem eventkey={5} >
                                        <YouTubeSubscribe
                                            // channelName={channelName}
                                            channelid="UCSDGgBFmjPNTjAY3fBqr2wA"
                                            theme={"default"}
                                            layout={"full"}
                                            count={"default"}
                                        />
                                    </NavItem> */}
                            {/* <NavItem eventkey={6} href={`/${user.nickname}`}>
                                    <Nav.Link as={Link} to={`/${user.nickname}`}>
                                        Signed in as {user.nickname}
                                    </Nav.Link> */}
                            {/* <img
                                            src={user.picture}
                                            alt="Profile"
                                            // className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                                            className="rounded-circle img-profile profile-picture"

                                        /> */}
                            {/* </NavItem> */}
                            {/* <NavItem eventkey={6} >
                                    <img
                                        src={user.picture}
                                        alt="Profile"
                                        // className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                                        className="rounded-circle img-profile profile-picture"

                                    />
                                </NavItem> */}


                        </Nav>

                    </Navbar.Collapse>
                </Navbar>
            </Row>
        </>
    );
}

export default Navigation;
