import React, { useMemo, useContext, useEffect } from 'react';
import { useTable, useFilters, usePagination, useRowSelect } from 'react-table';
import AppContext from '../context/index';
import { relatedCardsColumns } from '../helpers/columns'
import { Checkbox } from './Checkbox';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Table, Row, Button, Modal } from "react-bootstrap";



function RelatedCardsTable(props) {

    //get the url information from react router useLocation hook
    let location = useLocation();

    //split the path into an array.  The length of this array is used to determine which component to render
    let pathValues = location.pathname.split('/')

    let status = pathValues[3];

    // console.log("pathValues in related cards table " + JSON.stringify(pathValues))
    // console.log("pathValues 3 is " + pathValues[2])

    //set up context
    const { example, setExample } = useContext(AppContext);

    const [selection, setSelection] = React.useState([]);
    const [cardSent, setCardsSent] = React.useState(false);

    const columns = useMemo(() => relatedCardsColumns, [])
    const data = useMemo(() => example?.notOwnedRelatedCards, [example.notOwnedRelatedCards])

    const {
        getTableProps,
        getTableBodyProps,
        selectedFlatRows,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useFilters,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    card_id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <Checkbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
                },
                ...columns
            ])
        }
    )
    const { pageIndex, pageSize } = state
    const postData = async (data) => {

        axios
            .post(
                "https://webhooks.mongodb-realm.com/api/client/v2.0/app/cards-fvyrn/service/AddCards/incoming_webhook/AddCards",
                data
            )
            .catch((error) => {
                console.log(error);
            });

        setCardsSent(true)
    }

    useEffect(() => {
        setSelection(selectedFlatRows.map(row => row.original));
        // console.log("HERE IS SELECTION " + JSON.stringify(selection))
    }, [setSelection, selectedFlatRows]);



    return (
        <>
            <h2>Cards related to {pathValues[5].replaceAll('%20', ' ')} {pathValues[7].replaceAll('%20', ' ')}</h2>
            {selectedFlatRows.length > 0 ? (
                <button className="btn btn-primary"
                    onClick={() => postData(selection)}>
                    {/* <button className="btn btn-primary"> */}
                    <i className="ri-upload-cloud-line ri-lg"></i>Add Selected Cards to {status}ed List
                </button>
            ) : null}
            {cardSent && <Modal
                size="sm"
                show={cardSent}
                onHide={() => setCardsSent(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Success!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{selectedFlatRows.length} cards have been added to your {status}ed collection</Modal.Body>
            </Modal>
            }
            <Row>
                <Table striped bordered hover responsive {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>

                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>


                </Table>
                <div>
                    <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </Button>{' '}
                    <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </Button>{' '}
                    <Button variant="success" onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </Button>{' '}
                    <Button variant="success" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </Button>{' '}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type='number'
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            style={{ width: '50px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => setPageSize(Number(e.target.value))}>
                        {[10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <pre>
                    <code>
                        {JSON.stringify(
                            {
                                selectedFlatRows: selectedFlatRows.map(row => row.original)
                            },
                            null,
                            2
                        )}
                    </code>
                </pre> */}
            </Row>
        </>
    )
}

export default RelatedCardsTable