import React, { useContext, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import AppContext from '../context/index';


function ImageModal(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    console.log("IN IMAGE MODAL")

    const { example, setExample } = useContext(AppContext);

    return (
        //taken from the react bootstrap example at https://react-bootstrap.github.io/components/modal/
        <>
            <Modal size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={example.modalShow} onHide={() => setExample((prevcontext => {
                    return {
                        ...prevcontext,
                        modalShow: false,
                    }
                }))}  >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {example.imageModalValues.player} <br />
                        {example.imageModalValues.brand} -{" "}
                        {example.imageModalValues.series}-{" "}
                        {example.imageModalValues.variation}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={`${example.imageModalValues.imagePath}/${example.imageModalValues.userName}-${example.imageModalValues.clickedCardID}.jpg`}
                        alt={props.player}
                        width="1080"
                        className="img-fluid"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setExample((prevcontext => {
                                return {
                                    ...prevcontext,
                                    modalShow: false,

                                }
                            }))
                        }
                        }
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ImageModal