import React, { useContext } from 'react';
import AppContext from '../context/index';
import { useParams } from "react-router-dom";
import { findTeam } from '../helpers/helpers'
import CardComponent from './CardComponent';
import { Container, Row, CardGroup, Col } from "react-bootstrap";
import { EbayTable } from './Ebay';
import RelatedCards from './RelatedCards';
import { useAuth0 } from '@auth0/auth0-react';



const CardColumns = (props) => {

    //get the authentication boolean and user from Auth0
    const { isLoading, isAuthenticated, user } = useAuth0();

    // console.log("PROPS " + JSON.stringify(props))

    let params = useParams();
    console.log("PARAMS in card columns" + JSON.stringify(params))


    let paramValues = Object.keys(params).length;

    console.log("LENGTH OF PARAMS object is " + paramValues)

    const { example, setExample } = useContext(AppContext);

    //filter the owned or wanted cards array to just those of a particular brand, variation etc 
    // we take the url params as the filters to filter the cardsArray
    const filterCards = (cardsArray, filters) => {
        // console.log('cardsarray is ' + JSON.stringify(cardsArray))

        let filtered = cardsArray.filter((card) =>
            Object.entries(filters).every(([key, value]) => card[key] === value)
        );
        return filtered;
    };

    //this is teh object of cards after they have been filtered by team, id, variation etc
    const data = filterCards(props.data, params)

    // console.log('DATA from cardsarray is ' + JSON.stringify(data))
    //initiate the team name variable
    let teamName = ""
    //check if the url params contain a card ID or a team name.  If they do we are going to set team colours.  
    if (params.card_id || params.team) {
        if (params.team) {
            teamName = params.team
        }
        if (params.card_id) {
            teamName = data[0]?.team
        }
        // pass the teams array from context object, and the teamName as set above, to the findTeam function which is called in from helpers.js
        const teamColours = findTeam(example.teams, teamName)

        document.documentElement.style.setProperty(
            "--team-primary",
            teamColours?.primary
        );
        document.documentElement.style.setProperty(
            "--team-secondary",
            teamColours?.secondary
        );

    } else {
        //console.log("WASNT ABLE TO GET TEAM")
        document.documentElement.style.setProperty(
            "--team-primary",
            "#773141"
        );
        document.documentElement.style.setProperty(
            "--team-secondary",
            "#ffb612"
        );
    }

    return (
        <>{data.length > 0 ? <>

            <Container className="cards" props={props}>
                {/* the call to this.context.filterCards in componentDidUpdate sets the
        filteredCards array in context.js which is available here and mapped
        over */}
                {/* <Row> */}
                <h1>{params.year} {params.brand}</h1>
                <CardGroup props={props}>{data.map((card) => <CardComponent {...props} card={card} />)}</CardGroup>
                {params.card_id ?
                    // <Col>
                    //     <Row>
                    //         {/* Show current ebay auctions for the card that is showing. */}
                    //         {/* <Ebay {...this.props} /> */}
                    //         <EbayTable card={data} />

                    //     </Row>
                    // </Col> 
                    <EbayTable card={data} />
                    : <p></p>}
                {/* if the params from react router contains more than two values (i.e. it is showing a collection of cards by year) then show related cards */}
                {/*  TODO: **SPORT DYNAMIC** - does the number of params need to be updated to take into account the dynamic sport value? */}
                {paramValues > 3 && isAuthenticated ? <RelatedCards {...params} /> : <></>}
            </Container>
        </>
            : <h1>Sorry, card data not found</h1>}</>
    );
};

export default CardColumns;