import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import {
  BrowserRouter
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { UserContext, UserContextProvider } from "./context/UserContext";
import ContextProvider from './context/ContextProvider';



ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ContextProvider>
        <Auth0ProviderWithHistory>
          {/* <UserContextProvider> */}
          <App />
          {/* </UserContextProvider> */}
        </Auth0ProviderWithHistory>
      </ContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
