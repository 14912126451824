import React, { useState } from 'react';
import AppContext from './index';
import { teams } from '../helpers/teams'
const ContextProvider = ({ children }) => {
    const [example, setExample] = useState(
        {
            modalShow: false,
            imageModalValues: { clickedCardID: "", player: "", brand: "", series: "", userName: '', imagePath: '', variation: '' },
            teams: teams,
            dataLoaded: false,
            selectedRelatedCards: [],
            ownedCards: [],
            wantedCards: [],
            wantedCardsFromOtherUsers: [],
            randomCards: [],
            viewedUser: '',
            nonAuthenticatedUsersWantedCards: [],
            nonAuthenticatedUsersOwnedCards: [],
            notOwnedRelatedCards: [],
            imageHandling: {
                secureS3URL: "",
                selectedFile: "",
                fileUploaded: false
            },
        }
    )
    const context = {
        setExample,
        example,
    };
    return (
        <AppContext.Provider value={context}>
            {children}
        </AppContext.Provider>
    );
}
export default ContextProvider;