import axios from "axios";

export const findTeam = (object, key) => {
    //console.log("IN FIND TEAM " + JSON.stringify(object))
    var value;
    Object.keys(object).some(function (k) {
        if (k === key) {
            value = object[k];
            return true;
        }
    });
    return value;
}


export const refreshEbayTokenSetup = () => {
    console.log("*******IN REFREsh")
    // Timing to renew access token
    //set an initual timing variable in seconds
    let refreshTiming = 1000;

    //create a refresh function which gets the auth key from ebay and refreshes it the returned expires_in value (in seconds) is over
    const refreshToken = async () => {
        //console.log("TOKEN IS GOING TO BE SEY");

        const response = await axios.get('https://n6rvf22nv1.execute-api.eu-west-2.amazonaws.com/prod/token')
            .then((response) => {
                //console.log("TOKEN IS " + response.data.access_token)
                localStorage.setItem("authTokenEbay", response.data.access_token);
                //convert the expires_in value to seconds for use in setTimout
                refreshTiming = response.data.expires_in * 1000;
            });


        // Setup the other timer after the first one
        setTimeout(refreshToken, refreshTiming);
    };

    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming);
};


export const getUsersCards = async (username) => {
    try {
        const [ownedCardsResponse, wantedCardsResponse] = await Promise.all([
            fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/cards-fvyrn/service/Cards/incoming_webhook/getOwnedCards?userName=jinky32`),
            fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/cards-fvyrn/service/Cards/incoming_webhook/getWantedCards?userName=jinky32`)
        ]);
        const ownedCards = await ownedCardsResponse.json();
        const wantedCards = await wantedCardsResponse.json();
        // //console.log(ownedCards, 'ownedCards');
        // //console.log(wantedCards, 'wantedCards');
        return (
            {
                ownedCards: ownedCards,
                wantedCards: wantedCards,
            }
        )
        //   setExample((prevcontext => {
        //     return {
        //       ...prevcontext,
        //       age: 51,
        //       dataLoaded: true,
        //       ownedCards: ownedCards,
        //       wantedCards: wantedCards,
        //       //     nonAuthenticatedUsersWantedCards: [],
        //       // nonAuthenticatedUsersOwnedCards: []
        //     }
        //   }))
    } catch (err) {
        throw err;
    }
};