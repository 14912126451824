//as per https://auth0.com/blog/complete-guide-to-react-user-authentication/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
    // const domain = "dev-lnnmrfn4.us.auth0.com";
    // const clientId = "MpXkXU5lceJdt3kmSzdBvcXyeME6m7IZ";
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    //     REACT_APP_AUTH0_DOMAIN=dev-lnnmrfn4.us.auth0.com
    // REACT_APP_AUTH0_CLIENT_ID=MpXkXU5lceJdt3kmSzdBvcXyeME6m7IZ

    // const history = useNavigate();
    const navigate = useNavigate();


    const onRedirectCallback = (appState) => {
        //console.log("THIS IS APP STATE" + JSON.stringify(appState))
        // history.push(appState?.returnTo || window.location.pathname);
        navigate(appState?.returnTo || window.location.pathname);

    };

    // if (!isAuthenticated) {
    //     loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    // }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;