import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import AppContext from '../context/index';
import RelatedCardsTable from "./RelatedCardsTable";
import { useLocation } from "react-router-dom";



function RelatedCards(props) {

    console.log("THESE ARE PROPS IN RELATED CARDS " + JSON.stringify(props))

    //get the url information from react router useLocation hook
    let location = useLocation();

    //split the path into an array.  The length of this array is used to determine which component to render
    let pathValues = location.pathname.split('/')
    // TODO: **SPORT DYNAMIC** - will status still be the second value after sport is added?
    let status = pathValues[3];
    let capitailsed = status[0].toUpperCase() + status.substring(1)

    //set up context
    const { example, setExample } = useContext(AppContext);

    //get the authentication boolean and user from Auth0
    const { isLoading, isAuthenticated, user } = useAuth0();

    if (isAuthenticated) {
        console.log("USER IS " + JSON.stringify(user))
    }

    const [relatedCards, setRelatedCards] = useState([]);

    //get the filters from props and split the object proteperties into an array
    let argumentArray = Object.entries(props);

    //define the string that will be appended to the API call
    var queryString = "";

    //Loop through the items in the array (starting from the 3rd) and construct the query string
    for (let i = 1; i < Object.keys(argumentArray).length; i++) {
        queryString += argumentArray[i][0] + "=" + argumentArray[i][1] + `&`;
    }

    const loadRelatedCardData = async (queryString, user) => {
        // //merge the user's owned and wanted cards into a combined array so that it can be compared to related cards array
        let allUserCards = [
            ...example.ownedCards,
            ...example.wantedCards,
        ];
        let cardOwnerEmail = user.email;
        let cardOwnerName = user.nickname;
        //  TODO: **SPORT DYNAMIC** - the below endPoint var needs to have ?sport as dynamic value not hardcoded
        //  TODO: **SPORT DYNAMIC** - should the param be sport or 'collection' to make it more generally relevant (e.g. pokemon)
        // const endPoint = `https://webhooks.mongodb-realm.com/api/client/v2.0/app/cards-fvyrn/service/Cards/incoming_webhook/getCardsFromChecklists?sport=Football&${queryString}`;
        const endPoint = `https://webhooks.mongodb-realm.com/api/client/v2.0/app/cards-fvyrn/service/Cards/incoming_webhook/getCardsFromChecklists?${queryString}`;

        const returnedCards = await fetch(endPoint)
        const data = await returnedCards.json()
        //go through the returned data and add in details so that it can be compared to a users already owned cards
        data.forEach(function (row) {
            row.user = cardOwnerEmail;
            row.userName = cardOwnerName;
            row.status = capitailsed;
            row.number = "0";
            row.card_id = row._id.$oid;
            delete row._id; // delete the original mongo document ID so that more than one user can add the same card to the DB

        });

        //     // this code is based on my question on SO at https://stackoverflow.com/questions/66341863/remove-objects-in-an-array-based-on-another-array-of-objects-where-specific-prop
        //     //this code checks whether an object with the same card number and series name appear in both arrays. If they do the record is removed from teh raltedcards array

        //TODO: this doens't seem to filter out cards on pages like http://localhost:3000/jinky32/own/year/2017/brand/National%20Treasures/variation/Holo%20Silver
        //do I need to add `+ " / " + x.varaition` to the below to filter out variation if it exists?
        let allUserCardsSet = new Set(
            allUserCards.map((x) => x.card_number + " / " + x.series)
        );
        let notOwnedRelatedCards = data.filter(
            (x) => !allUserCardsSet.has(x.card_number + " / " + x.series)
        );

        setRelatedCards((prevstate => {
            return {
                ...prevstate,
                data: notOwnedRelatedCards
            }
        }))

        setExample((prevcontext => {
            return {
                ...prevcontext,
                notOwnedRelatedCards: notOwnedRelatedCards
            }
        }))
    };

    useEffect(() => {
        loadRelatedCardData(queryString, user);
    }, [queryString, user]);
    return (
        <>
            <RelatedCardsTable />
        </>
    )
}

export default RelatedCards