import { ColumnFilter } from "../components/ColumnFilter";
import {
    ButtonGroup,
    Dropdown,
    DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";


const EbayColumns = [
    {

        Header: "Item Image",
        accessor: "image.imageUrl",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                <>
                    {/* <img src={row.original.card_title} alt={row.original.card_title} ></img> */}
                    <a href={row.original.itemAffiliateWebUrl} target="_blank" rel="noopener noreferrer">
                        <img src={row.original.image.imageUrl} alt={row.original.card_title} ></img>
                    </a>
                </>
                : "-",
    },
    {
        Header: "Title",
        accessor: "title",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                <>
                    {/* <img src={row.original.card_title} alt={row.original.card_title} ></img> */}
                    <a href={row.original.itemAffiliateWebUrl} target="_blank" rel="noopener noreferrer">{value}
                    </a>
                </>
                : "-",

    },
    {
        Header: "Date Added",
        accessor: "itemCreationDate",
        Filter: ColumnFilter

    },
    {
        Header: "Price",
        accessor: "price.value",
        Filter: ColumnFilter

    }
]

const cardsColumns = [
    {
        Header: "Year",
        accessor: "year",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                <Link
                    // TODO: **SPORT DYNAMIC** - these kinds of paths will need to include sport
                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/year/${row.original.year}`}
                >
                    {value}
                </Link>
                : "-",
    },
    {
        Header: "Card Title",
        accessor: "card_title",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>

            row.original.grader ?
                <>
                    {value}
                    <br />
                    <ButtonGroup vertical>
                        <DropdownButton
                            variant="outline-primary"
                            as={ButtonGroup}
                            title="View Options"
                            id="bg-vertical-dropdown-1"
                        >
                            <Dropdown.Item
                                eventKey="1"
                            >
                                <Link
                                    to={{ pathname: `//www.psacard.com/cert/${row.original.graded_id}` }}
                                    target="_blank" >
                                    View on {row.original.grader}
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                            >
                                {/*  TODO: **SPORT DYNAMIC** - */}
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/${row.original.card_id}`}
                                >
                                    View This Card
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item
                                eventKey="2"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/card_title/${row.original.card_title}`}
                                >
                                    All Cards

                                </Link>
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </>
                : <>
                    {value}
                    <br />
                    <ButtonGroup vertical>
                        <DropdownButton
                            variant="outline-primary"
                            as={ButtonGroup}
                            title="View Options"
                            id="bg-vertical-dropdown-1"
                        >
                            <Dropdown.Item
                                eventKey="2"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/${row.original.card_id}`}
                                >
                                    View This Card
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item
                                eventKey="2"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/card_title/${row.original.card_title}`}
                                >
                                    {/* {row.year} cards */}
                                    All Cards

                                </Link>
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </>,
    },
    {
        Header: "Brand",
        accessor: "brand",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                <>
                    {value}
                    <br />
                    <ButtonGroup vertical>
                        <DropdownButton
                            variant="outline-primary"
                            as={ButtonGroup}
                            title="View Options"
                            id="bg-vertical-dropdown-1"
                        >
                            <Dropdown.Item
                                eventKey="1"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/brand/${row.original.brand}`}
                                >
                                    All years
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item
                                eventKey="2"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/year/${row.original.year}/brand/${row.original.brand}`}
                                >
                                    {/* {row.year} cards */}
                                    {row.original.year} cards

                                </Link>
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </>
                : "-",

    },
    {
        Header: "Series",
        accessor: "series",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                <>
                    {value}
                    <br />
                    <ButtonGroup vertical>
                        <DropdownButton
                            variant="outline-primary"
                            as={ButtonGroup}
                            title="View Options"
                            id="bg-vertical-dropdown-1"
                        >
                            <Dropdown.Item
                                eventKey="1"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/series/${row.original.series}`}
                                >
                                    All years
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item
                                eventKey="2"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/year/${row.original.year}/series/${row.original.series}`}
                                >
                                    {/* {row.year} cards */}
                                    {row.original.year} cards

                                </Link>
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </>
                : "-",

    },
    {
        Header: "Variation",
        accessor: "variation",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                <>
                    {value}
                    <br />
                    <ButtonGroup vertical>
                        <DropdownButton
                            variant="outline-primary"
                            as={ButtonGroup}
                            title="View Options"
                            id="bg-vertical-dropdown-1"
                        >
                            <Dropdown.Item
                                eventKey="1"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/brand/${row.original.brand}/variation/${row.original.variation}`}
                                >
                                    All years
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item
                                eventKey="2"
                            >
                                <Link
                                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/year/${row.original.year}/brand/${row.original.brand}/variation/${row.original.variation}`}
                                >
                                    {row.original.year} cards

                                </Link>
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </>
                : "-",

    },
    {
        Header: "Team",
        accessor: "team",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?


                <Link
                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/team/${row.original.team}`}
                >
                    {value}
                </Link>
                : "-",

    },
    // {
    //     Header: "Available for Purchase or Trade",
    //     accessor: "forTrade",
    //     Filter: ColumnFilter,
    //     Cell: ({ cell: { row, value } }) =>
    //         value ?


    //             <Link
    //                 to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/forTrade/${row.original.forTrade}`}
    //             >
    //                 {value}
    //             </Link>
    //             : "-",

    // },
]

const relatedCardsColumns = [
    // {
    //     Header: "card_id",
    //     accessor: "card_id",
    //     Filter: ColumnFilter
    // }, 
    {
        Header: "Card Title",
        accessor: "card_title",
        Filter: ColumnFilter
    },
    {
        Header: "Brand",
        accessor: "brand",
        Filter: ColumnFilter,
        disableFilters: true

    },
    {
        Header: "Series",
        accessor: "series",
        Filter: ColumnFilter

    },
    {
        Header: "Variation",
        accessor: "variation",
        Filter: ColumnFilter

    },
    {
        Header: "Team",
        accessor: "team",
        Filter: ColumnFilter

    },
]


// [{ "_id": { "$oid": "6234cf39ff696a8bc23f0062" }, "card_id": "61ddf66d68ebbba1a4085f16", "number": "", "userName": "tcctradingcardclub", "brand": "Contenders", "card_number": "165", "card_title": "Byron Cowart", "career_stage": "", "collection_type": "Football", "forTrade": "Yes", "graded_id": "", "grader": "", "manufacturer": "Panini", "print_run": "", "purchase_date": "", "series": "Rookie Ticket", "status": "Own", "team": "New England Patriots", "user": "tcctradingcardclub@gmail.com", "variation": "Championship Ticket", "year": "2019" }]

const wantedCardsColumns = [
    {
        Header: "Card Owner",
        accessor: "userName",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                < a href={'mailto:' + row.original.user + '?subject=Regarding ' + row.original.card_title + ' for Trade on TradingCard Club'} target="_blank" > {value} (email them)</a>
                : "-",
    },
    {
        Header: "Card Title",
        accessor: "card_title",
        Filter: ColumnFilter,
        Cell: ({ cell: { row, value } }) =>
            value ?
                <Link
                    // TODO: **SPORT DYNAMIC** - these kinds of paths will need to include sport
                    to={`/${row.original.userName}/${row.original.collection_type}/${row.original.status.toLowerCase()}/${row.original.card_id}`}
                >
                    {value}
                </Link>
                : "-",
    },
    {
        Header: "Brand",
        accessor: "brand",
        Filter: ColumnFilter,
        // disableFilters: true

    },
    {
        Header: "Series",
        accessor: "series",
        Filter: ColumnFilter

    },
    {
        Header: "Variation",
        accessor: "variation",
        Filter: ColumnFilter

    },
    {
        Header: "Team",
        accessor: "team",
        Filter: ColumnFilter

    },
]

export { cardsColumns, relatedCardsColumns, EbayColumns, wantedCardsColumns };