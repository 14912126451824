import React, { useMemo, useContext, useEffect } from 'react';
import AppContext from '../context/index';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useParams } from "react-router-dom";
import { getUsersCards } from '../helpers/helpers'
import CardTable from './CardTable';
import CardColumns from './CardColumns';
import { Container, Row } from "react-bootstrap";

const CollectionContainer = (props) => {

    //get the url information from react router useLocation hook
    let location = useLocation();

    //split the path into an array.  The length of this array is used to determine which component to render
    let pathValues = location.pathname.split('/')
    console.log("location.pathname" + JSON.stringify(location.pathname))

    console.log("path values in collection container" + JSON.stringify(pathValues))
    console.log("path values in collection container length is " + pathValues.length)

    //get the url params frmo react router
    let params = useParams();

    //get the authentication boolean and user from Auth0
    const { isLoading, isAuthenticated, user } = useAuth0();

    //set up context
    const { example, setExample } = useContext(AppContext);

    //this function calls the getUsersCards function in helpers.  It is used here to get card for non-authenticated users / pages that don't belong to the authenticated user
    // const getCards = async (username) => {
    //     try {
    //         const usersCards = await getUsersCards(username);
    //         setExample((prevcontext => {
    //             return {
    //                 ...prevcontext,
    //                 nonAuthenticatedUsersWantedCards: usersCards.wantedCards,
    //                 nonAuthenticatedUsersOwnedCards: usersCards.ownedCards,
    //                 viewedUser: params.userName
    //             }
    //         }))
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    //usememo conditional taken from https://stackoverflow.com/questions/68733169/react-how-can-i-use-usememo-conditionally
    //we set data either to the user whose page is being used (non-authenticated), which should be in context after getCards is called
    const data = useMemo(() => {
        // if (example.viewedUser === params.userName) {
        //     if (props.status === "Want") {
        //         return example.nonAuthenticatedUsersWantedCards;
        //     } else if (props.status === "Own")
        //         return example.nonAuthenticatedUsersOwnedCards;
        // } // or to the authenticated users cards which should be in context after being called in app.js and set to context
        // else if (isAuthenticated) {
        //     if (props.status === "Want") {
        //         return example.wantedCards;
        //     } else if (props.status === "Own")
        //         return example.ownedCards;
        // }
        if (props.status === "Want") {
            return example.wantedCards;
        } else if (props.status === "Own")
            return example.ownedCards;
    }, [example, params, props]);

    // console.log("DATA" + JSON.stringify(data))

    //if the user isn't logged in or the page of a different user is being viewed, we need to get that users cards
    // useEffect(() => {
    //     //if the user is not authenticated, and the viewed users cards are not already in context
    //     if (!isAuthenticated && example.viewedUser != params.userName) {
    //         getCards(params.userName)
    //     } //if the user is authenticated, but they are viewing another users cards that aren't already in context
    //     else if ((isAuthenticated && user.nickname != params.userName) && (example.viewedUser != params.userName)) {
    //         getCards(params.userName)
    //     }
    // }, [params.userName]);


    return (
        <>
            {/*  TODO: **SPORT DYNAMIC** - will we want length to still be 3 after sport / collection is added? */}
            {/* {pathValues.length === 3 && data && */}
            {pathValues.length <= 4 && data &&
                <Container className="cardtable" props={props}>
                    <Row>
                        <CardTable data={data} props={props} />
                    </Row>
                </Container>
            }
            {pathValues.length >= 5 && data && <CardColumns data={data} />}
        </>
    );
};

export default CollectionContainer;