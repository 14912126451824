import React, { useMemo } from 'react';
import { useParams } from "react-router-dom";
import { cardsColumns } from '../helpers/columns'
import { Table, Row, Button } from "react-bootstrap";
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';


const CardTable = (props) => {

    const sortees = React.useMemo(
        () => [
            {
                id: "year",
                desc: false
            }
        ],
        []
    );
    // console.log("CardTable props data" + JSON.stringify(props.data))
    //get the URL params from react-router
    let params = useParams();

    const filterCards = (cardsArray, filters) => {
        let filtered = cardsArray.filter((card) =>
            Object.entries(filters).every(([key, value]) => card[key] === value)
        );
        return filtered;
    };
    // console.log("params in card table " + JSON.stringify(params))

    // initialise the variable so that we can get at the value that is modified in the if statement
    let newcarddata = []
    //check if the url contains the collection_type param
    if (params.collection_type) {
        // console.log("yes")
        //and if it does filter the cards by the collection_type (Football, Hockey, Pokemon etc)
        newcarddata = filterCards(props.data, params)
        // console.log("HERE" + JSON.stringify(newcarddata))
    } else {
        // console.log("no")
    }
    // console.log("CardTable newcarddata props data" + JSON.stringify(newcarddata))


    const columns = useMemo(() => cardsColumns, [])
    // const data = useMemo(() => props?.data, [props?.data])


    //usememo conditional taken from https://stackoverflow.com/questions/68733169/react-how-can-i-use-usememo-conditionally
    //we set data either to the returned cards filtered by collection_type, or show all cards across all collections
    const data = useMemo(() => {
        if (params.collection_type) {
            // console.log("yes")
            //and if it does filter the cards by the collection_type (Football, Hockey, Pokemon etc)
            return newcarddata
            // return newcarddata
            // console.log("HERE" + JSON.stringify(newcarddata))
        } else {
            return props?.data
        }
    }, [params.collection_type, props.data]);

    const {
        getTableProps,
        getTableBodyProps,
        selectedFlatRows,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: sortees
            }
            // initialState: {
            //     sortBy: [
            //         {
            //             id: 'Year',
            //             desc: true
            //         }
            //     ]
            // }
            // initialState: { pageIndex: 0 }
        },
        useFilters,
        useSortBy,
        usePagination

    )
    const { pageIndex, pageSize } = state

    return (
        <>{data ? <><h1>My {props.props.status}ed Cards</h1>
            <Row>
                <Table striped bordered hover responsive {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? " 🔽"
                                                    : " 🔼"
                                                : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>


                </Table>
                <div>
                    <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </Button>{' '}
                    <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </Button>{' '}
                    <Button variant="success" onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </Button>{' '}
                    <Button variant="success" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </Button>{' '}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type='number'
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            style={{ width: '50px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => setPageSize(Number(e.target.value))}>
                        {[10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </Row>
        </>
            : <h1>Data not here</h1>}</>
    );
};

export default CardTable;