import React from 'react'

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column

    return (
        <span>
            {/* Search:{' '} */}
            <input value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} placeholder="filter cards" />
        </span>
    )
    // return (
    //     <span>
    //         Search:{' '}
    //         <input
    //             value={value || ""}
    //             onChange={e => {
    //                 setValue(e.target.value);
    //                 onChange(e.target.value);
    //             }}
    //             placeholder={`${count} records...`}
    //             style={{
    //                 fontSize: '1.1rem',
    //                 border: '0',
    //             }}
    //         />
    //     </span>
    // )
}