export const teams = {
    "Arizona Cardinals": {
        primary: "#000000",
        secondary: "#97233f",
        coordinates: {
            lat: 33.5276,
            lon: -112.263275
        }
    },
    "Atlanta Falcons": {
        primary: "#000000",
        secondary: "#a71930",
        coordinates: {
            lat: 33.755489,
            lon: -84.401993
        }
    },
    "Baltimore Ravens": {
        primary: "#9e7c0c",
        secondary: "#241773",
        coordinates: {
            lat: 39.278088,
            lon: -76.623322
        }
    },
    "Buffalo Bills": {
        primary: "#00274d",
        secondary: "#c60c30",
        coordinates: {
            lat: 40.712784,
            lon: -74.005941
        }
    },
    "Carolina Panthers": {
        primary: "#000000",
        secondary: "#0085ca",
        coordinates: {
            lat: 35.225795,
            lon: -80.852842
        }
    },
    "Chicago Bears": {
        primary: "#0b162a",
        secondary: "#c83803",
        coordinates: {
            lat: 41.862366,
            lon: -87.617256
        }
    },
    "Cincinnati Bengals": {
        primary: "#000000",
        secondary: "#fb4f14",
        coordinates: {
            lat: 39.096306,
            lon: -84.516846
        }
    },
    "Cleveland Browns": {
        primary: "#311d00",
        secondary: "#ff3c00",
        coordinates: {
            lat: 41.506035,
            lon: -81.700058
        }
    },
    "Dallas Cowboys": {
        primary: "#002244",
        secondary: "#869397",
        coordinates: {
            lat: 32.747841,
            lon: -97.093628
        }
    },
    "Denver Broncos": {
        primary: "#002244",
        secondary: "#fb4f14",
        coordinates: {
            lat: 39.744129,
            lon: -105.020828
        }
    },
    "Detroit Lions": {
        primary: "#0076b6",
        secondary: "#b0b7bc",
        coordinates: {
            lat: 42.337331984,
            lon: -83.040666504
        }
    },
    "Green Bay Packers": {
        primary: "#203731",
        secondary: "#ffb612",
        coordinates: {
            lat: 44.50083133,
            lon: -88.057333104
        }
    },
    "Houston Texans": {
        primary: "#03202f",
        secondary: "#a71930",
        coordinates: {
            lat: 29.68416393,
            lon: -95.406498374
        }
    },
    "Indianapolis Colts": {
        primary: "#002c5f",
        secondary: "#a5acaf",
        coordinates: {
            lat: 39.759991,
            lon: -86.163712
        }
    },
    "Jacksonville Jaguars": {
        primary: "#006778",
        secondary: "#9f792c",
        coordinates: {
            lat: 30.323471,
            lon: -81.636528
        }
    },
    "Kansas City Chiefs": {
        primary: "#e31837",
        secondary: "#ffb612",
        coordinates: {
            lat: 33.953587,
            lon: -94.484566
        }
    },
    "Los Angeles Chargers": {
        primary: "#002244",
        secondary: "#ffb612",
        coordinates: {
            lat: 44.5013,
            lon: -118.339630
        }
    },
    "Los Angeles Rams": {
        primary: "#002244",
        secondary: "#b3995d",
        coordinates: {
            lat: 33.953587,
            lon: -118.339630
        }
    },
    "Miami Dolphins": {
        primary: "#008e97",
        secondary: "#f26a24",
        coordinates: {
            lat: 25.958062,
            lon: -80.23884
        }
    },
    "Minnesota Vikings": {
        primary: "#4f2683",
        secondary: "#ffc62f",
        coordinates: {
            lat: 44.973774,
            lon: -93.258736
        }
    },
    "New England Patriots": {
        primary: "#002244",
        secondary: "#c60c30",
        coordinates: {
            lat: 42.087832982,
            lon: -71.258665632
        }
    },
    "New Orleans Saints": {
        primary: "#000000",
        secondary: "#d3bc8d",
        coordinates: {
            lat: 29.951439,
            lon: -90.081970
        }
    },
    "New York Giants": {
        primary: "#0b2265",
        secondary: "#a71930",
        coordinates: {
            lat: 40.813778,
            lon: -74.074310
        }
    },
    "New York Jets": {
        primary: "#003f2d",
        secondary: "#FFFFFF",
        coordinates: {
            lat: 44.5013,
            lon: 88.0622
        }
    },
    "Oakland Raiders": {
        primary: "#000000",
        secondary: "#a5acaf",
        coordinates: {
            lat: 37.751605,
            lon: -122.200485
        }
    },
    "Las Vegas Raiders": {
        primary: "#000000",
        secondary: "#a5acaf",
        coordinates: {
            lat: 42.188997,
            lon: -122.691403
        }
    },
    "Philadelphia Eagles": {
        primary: "#004c54",
        secondary: "#a5acaf",
        coordinates: {
            lat: 39.900496398,
            lon: -75.167165998
        }
    },
    "Pittsburgh Steelers": {
        primary: "#000000",
        secondary: "#ffb612",
        coordinates: {
            lat: 40.441331568,
            lon: -80.009499962
        }
    },
    "San Francisco 49ers": {
        primary: "#aa0000",
        secondary: "#b3995d",
        coordinates: {
            lat: 37.77493,
            lon: -122.419416
        }
    },
    "Seattle Seahawks": {
        primary: "#aa0000",
        secondary: "#b3995d",
        coordinates: {
            lat: 47.595152,
            lon: -122.331639
        }
    },
    "Tampa Bay Buccaneers": {
        primary: "#34302b",
        secondary: "#d50a0a",
        coordinates: {
            lat: 27.975958,
            lon: -82.503693
        }
    },
    "Tennessee Titans": {
        primary: "#002244",
        secondary: "#c60c30",
        coordinates: {
            lat: 36.166479,
            lon: -86.77129
        }
    },
    "Washington Football Team": {
        primary: "#773141",
        secondary: "#ffb612",
        coordinates: {
            lat: 38.907643,
            lon: -76.864546
        }
    },
    "Washington Redskins": {
        primary: "#773141",
        secondary: "#ffb612",
        coordinates: {
            lat: 38.907643,
            lon: -76.864546
        }
    },
    "Washington Commanders": {
        primary: "#773141",
        secondary: "#ffb612",
        coordinates: {
            lat: 38.907643,
            lon: -76.864546
        }
    },
};
